import React, { ChangeEvent, FC, Key, useState } from "react";
import "./CreateProject.scss";
import { VerificationDocumentUploadCardPicker } from "../../Index";
import { useForm } from "react-hook-form";
import { projectType } from "../../../../../../../Models/projectType";
import stateNg from "../../../../../../../Data/stateNG.json";
import projectService from "../../../../../../../Services/projectService";
import { Logger } from "../../../../../../../Helpers/Logger";
import toast, { Toaster } from "react-hot-toast";

interface propsType {
  handleClose: () => void;
}
const CreateProject: FC<propsType> = ({ handleClose }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<projectType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [images, setImages] = useState<any>([]);
  const onSubmit = handleSubmit((data) => processForm(data));

  const processForm = async (data: projectType) => {
    setLoading(true);
    await projectService.create(data).then(
      (response: { data: { data: { projectUuid: string } } }) => {
        console.log("processForm response", response.data.data);

        startUploading(response.data.data?.projectUuid);
      },
      (error) => {
        setLoading(false);
        toast.error(error.response.data.error, {
          duration: 20000,
          className: "custom-alert bg-white text-dark",
          position: "top-right",
        });

        console.log("processForm error", error);
      }
    );
  };

  const changeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    fileName: string
  ) => {
    const fileList = Array.from(event.target.files as FileList);

    const updatedImages = fileList.map((fileItem: File) => {
      // Process each file item
      console.log("fileItem", fileItem);
      return { file: fileItem, name: fileName };
    });

    setImages([...images, ...updatedImages]);
  };

  const removeImage = (index: number) => {
    images.splice(index, 1);
    setImages(images);
  };

  const checkIfExist = (fName: string) => {
    const found = images.some((el: { name: any }) => el.name === fName);
    return found;
  };

  const startUploading = async (projectUuid: string) => {
    for (const [index, currentProductImage] of images.entries()) {
      const formData = new FormData();
      Logger("PRODUCT FINANCE FILE", currentProductImage.file);
      // Update the formData object
      formData.append("file", currentProductImage.file);

      await projectService.file(projectUuid, formData, "PROJECT_IMAGES").then(
        (res: any) => {
          if (index === images.length - 1) {
            setLoading(false);
            toast.success("Project saved succesfully", {
              duration: 20000,
              className: "custom-alert bg-white text-dark",
              position: "top-right",
            });
            handleClose();
          }
        },
        (error: any) => {
          console.log("image upload error", error);
          setLoading(false);
          toast.error(error.response.data.error, {
            duration: 20000,
            className: "custom-alert bg-white text-dark",
            position: "top-right",
          });
        }
      );
    }
  };

  return (
    <div className="CreateProject">
      <form onSubmit={onSubmit}>
        <div className="form-group mb-3">
          <label htmlFor="projectName">Project Name</label>
          <input
            type="text"
            id="projectName"
            className="form-control"
            {...register("name", {
              required: "This field is can not be empty",
            })}
            placeholder="Project Name"
          />
          {errors.name && (
            <span className="error text-danger">{errors.name.message}</span>
          )}
        </div>

        <div className="form-group mb-3">
          <label htmlFor="projectType">Project Type</label>
          <select
            id="projectType"
            className="form-control"
            placeholder="Project type"
            {...register("type", {
              required: "This field is can not be empty",
            })}
          >
            <option>--choose--</option>
            <option value="Shop">Shop</option>
            <option value="Office Spaces">Office Spaces</option>
            <option value="Flats and Apartments">Flats and Apartments</option>
            <option value="Semi Detached Bungalow">
              Semi Detached Bungalow
            </option>
            <option value="Semi Detached Duplex">Semi Detached Duplex</option>
            <option value="Co-working Space">Co-working Space</option>
            <option value="Detached Bungalow">Detached Bungalow</option>
            <option value="Flats and Apartments">Flats and Apartments</option>
            <option value="Semi Detached Bungalow">
              Semi Detached Bungalow
            </option>
            <option value="Semi Detached Duplex">Semi Detached Duplex</option>
            <option value="Co-working Space">Co-working Space</option>
            <option value="Detached Bungalow">Detached Bungalow</option>
            <option value="Shop In A Mall">Shop In A Mall</option>
            <option value="Self Contain">Self Contain</option>
            <option value="Mini Flats">Mini Flats</option>
            <option value="Detached Duplex">Detached Duplex</option>
            <option value="Terraced Bungalow">Terraced Bungalow</option>
            <option value="Commercial Properties">Commercial Properties</option>
            <option value="Terraced Duplex">Terraced Duplex</option>
          </select>
          {errors.type && (
            <span className="error text-danger">{errors.type.message}</span>
          )}
        </div>

        <div className="form-group mb-3">
          <label className="d-flex align-items-center">
            <span className="me-2">Is Mortgage Available?</span>
            <div className="form-check form-switch">
              <input
                type="checkbox"
                id="isMortgageAvailable"
                className="form-check-input mt-2"
                {...register("isMortgageAvailable")}
              />
            </div>
          </label>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="projectDetails">Project Details</label>
          <textarea
            id="projectDetails"
            className="form-control"
            placeholder="Project Details"
            {...register("details", {
              required: "This field is can not be empty",
            })}
          />
          {errors.details && (
            <span className="error text-danger">{errors.details.message}</span>
          )}
        </div>

        <div className="form-group mb-3">
          <label htmlFor="costperunit">Cost Per Unit</label>
          <input
            type="text"
            id="costperunit"
            className="form-control"
            placeholder="Cost per unit"
            {...register("costPerSlot", {
              required: "This field is can not be empty",
            })}
          />
          {errors.costPerSlot && (
            <span className="error text-danger">
              {errors.costPerSlot.message}
            </span>
          )}
        </div>
        <div className="form-group mb-3">
          <label htmlFor="currentStage">Minimum Number Of Unit</label>
          <input
            type="text"
            id="currentStage"
            className="form-control"
            placeholder="Minimum number of unit"
            {...register("minimumNumberOfSlot", {
              required: "This field is can not be empty",
            })}
          />
          {errors.minimumNumberOfSlot && (
            <span className="error text-danger">
              {errors.minimumNumberOfSlot.message}
            </span>
          )}
        </div>

        <div className="row">
          <div className="col-6">
            <div className="form-group mb-3">
              <label htmlFor="currentStage">Number of Units</label>
              <input
                type="text"
                id="currentStage"
                className="form-control"
                placeholder="Number of units"
                {...register("numberOfSlots", {
                  required: "This field is can not be empty",
                })}
              />
              {errors.numberOfSlots && (
                <span className="error text-danger">
                  {errors.numberOfSlots.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="form-group mb-3">
              <label htmlFor="currentStage">Start date</label>
              <input
                type="date"
                id="currentStage"
                className="form-control"
                placeholder="Start date"
                {...register("startDate", {
                  required: "This field is can not be empty",
                })}
              />
              {errors.startDate && (
                <span className="error text-danger">
                  {errors.startDate.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="form-group mb-3">
              <label htmlFor="currentStage">Initial Payment Percentage</label>
              <input
                type="text"
                id="currentStage"
                className="form-control"
                placeholder="Initial Payment Percentage"
                {...register("initialInvestmentPercentage", {
                  required: "This field is can not be empty",
                })}
              />
              {errors.initialInvestmentPercentage && (
                <span className="error text-danger">
                  {errors.initialInvestmentPercentage.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="form-group mb-3">
              <label htmlFor="currentStage">Duration</label>
              <input
                type="text"
                id="currentStage"
                className="form-control"
                placeholder="Duration"
                {...register("duration", {
                  required: "This field is can not be empty",
                })}
              />
              {errors.duration && (
                <span className="error text-danger">
                  {errors.duration.message}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="currentStage">Address</label>
          <input
            type="text"
            id="currentStage"
            className="form-control"
            placeholder="Address"
            {...register("address", {
              required: "This field is can not be empty",
            })}
          />
          {errors.address && (
            <span className="error text-danger">{errors.address.message}</span>
          )}
        </div>

        {/* <div className='form-group mb-3'>
                    <label htmlFor="locationOnMap">Location On Map</label>
                    <input type="text" id="locationOnMap" className='form-control' placeholder='Location On Map'  {...register("locationOnMap", { required: "This field is can not be empty"})}/>
                    {errors.locationOnMap && <span className='error text-danger'>{errors.locationOnMap.message}</span>}
               </div> */}

        <div className="form-group mb-3">
          <label htmlFor="currentStage">State</label>
          <select
            id="currentStage"
            className="form-control"
            placeholder="Your state"
            {...register("state", {
              required: "This field is can not be empty",
            })}
          >
            <option value="">--choose--</option>
            {stateNg[2].data?.map((state, index: Key) => (
              <option value={state.name} key={index}>
                {state.name}
              </option>
            ))}
          </select>
          {errors.state && (
            <span className="error text-danger">{errors.state.message}</span>
          )}
        </div>

        <VerificationDocumentUploadCardPicker
          title={"Project Image"}
          description={"Project Image"}
          changeHandler={(event: any, filename: string) =>
            changeHandler(event, filename)
          }
          name={"project_image"}
          doc={"PROJECT_IMAGES"}
          checkIfExist={checkIfExist}
          images={images}
          removeImage={(index) => removeImage(index)}
        ></VerificationDocumentUploadCardPicker>

        <div className="form-group mb-3">
          <button type="submit" className="btn create-btn" disabled={false}>
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}{" "}
            Create New Project
          </button>
        </div>
      </form>

      <Toaster></Toaster>
    </div>
  );
};

export default CreateProject;
