import React, { useState, useEffect, Key } from "react";
import "./EstateSubscriberPortfolioDetails.scss";
import {
  EstatePageTitle,
  HorizontalLoader,
} from "../../../../../../../Components";
import projectService from "../../../../../../../Services/projectService";
import { portfolioType } from "../../../../../../../Models/portfolioType";
import { Link, useParams } from "react-router-dom";
import ImageCollage from "../../../../../../../Components/ImageCollage/ImageCollage";
import { CurrencyFormatter } from "../../../../../../../Helpers";
import { TimeConverter } from "../../../../../../../Helpers/TimeConverter";

const EstateSubscriberPortfolioDetails = () => {
  const { projectSubscriptionUuid } = useParams<{
    projectSubscriptionUuid: string | undefined;
  }>();

  const [loadingRequest, setLoadingRequest] = useState(false);
  const [portfolio, setPortfolio] = useState<portfolioType>();

  const getMyProjectPortfolio = async () => {
    setLoadingRequest(true);
    await projectService
      .getMyProjectPortfolioDetails(projectSubscriptionUuid)
      .then(
        (response) => {
          console.log("getMyProjectPortfolio 001", response.data);
          setLoadingRequest(false);
          setPortfolio(response.data.data);
        },
        (error) => {
          setLoadingRequest(false);
          console.log("getMyProjectPortfolio error", error);
        }
      );
  };

  useEffect(() => {
    getMyProjectPortfolio();
  }, []);

  return (
    <div className="EstateSubscriberPortfolioDetails pt-4">
      <div className="container">
        <div className="top-header">
          {portfolio && (
            <EstatePageTitle
              title={portfolio.project.name}
              parent="portfolio"
              parentUrl="subscriber-portfolio"
              showTitle={false}
            />
          )}

          {loadingRequest && <HorizontalLoader></HorizontalLoader>}

          {portfolio && (
            <div className="row mt-4">
              <div className="col-md-8">
                <div className="page-title">
                  <h2>{portfolio.project.name}</h2>
                  <span className="ids">ID: #001</span>
                  <span className="open">{portfolio.project.status}</span>
                </div>
              </div>
              <div className="col-md-4 mt-auto text-md-end"></div>
            </div>
          )}
        </div>
      </div>

      {portfolio && (
        <div className="container mt-3">
          <div className="row">
            <div className="col-md-7">
              <ImageCollage images={portfolio?.project.images}></ImageCollage>
            </div>
            <div className="col-md-5">
              <div className="price">
                ₦{CurrencyFormatter(portfolio?.project.costPerSlot)}{" "}
                <span className="unit">perunit</span>
              </div>

              <div className="project-content mt-3">
                <div className="row">
                  <div className="col-4 mt-3">
                    <div className="label">Project Type:</div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="amount">{portfolio?.project.type}</div>
                  </div>
                  <div className="col-4 mt-3">
                    <div className="label">Project Details:</div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="amount">{portfolio?.project.details}</div>
                  </div>
                  <div className="col-4 mt-3">
                    <div className="label">Current Stage:</div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="amount">-</div>
                  </div>
                  <div className="col-4 mt-3">
                    <div className="label">Number of Units:</div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="amount">
                      - <span className="bg-light"> sold</span>
                    </div>
                  </div>
                  <div className="col-4 mt-3">
                    <div className="label">Start Date:</div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="amount">{portfolio?.project.startDate}</div>
                  </div>
                  <div className="col-4 mt-3">
                    <div className="label">Initial Percentage:</div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="amount">
                      {portfolio?.project.initialInvestmentPercentage}%
                    </div>
                  </div>

                  <div className="col-4 mt-3">
                    <div className="label">Duration:</div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="amount">
                      {portfolio?.project.duration} months
                    </div>
                  </div>

                  <div className="col-4 mt-3">
                    <div className="label">Mortgage Available:</div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="amount">
                      {portfolio?.project.isMortgageAvailable === true
                        ? "YES"
                        : "NO"}
                    </div>
                  </div>

                  <div className="col-4 mt-3">
                    <div className="label">Duration Left:</div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="amount">
                      {portfolio?.durationLeft} month
                      {portfolio?.durationLeft > 1 && "s"}
                    </div>
                  </div>

                  <div className="col-4 mt-3">
                    <div className="label">Address</div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="amount">{portfolio?.project.address}</div>
                  </div>
                  <div className="col-4 mt-3">
                    <div className="label">State</div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="amount">{portfolio?.project.state}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {portfolio && (
        <div className="container">
          <div className="card subscribers-card">
            <div className="card-header bg-white">
              <div className="row">
                <div className="col-6">
                  <div className="title">Transactions</div>
                </div>
                <div className="col-6 text-end">
                  <div className="see-all">
                    <Link to="/estate-managers/subscriber-transactions">
                      See all
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table">
                  <thead className="">
                    <tr>
                      <td>Date</td>
                      <td>Amount Paid</td>
                      <td>Remaining Amount</td>
                    </tr>
                  </thead>
                  <tbody>
                    {portfolio &&
                      portfolio.susbscriptionTransactions.map(
                        (trans, index: Key) => (
                          <tr key={index}>
                            <td>{TimeConverter(trans.createdAt)}</td>
                            <td>₦{CurrencyFormatter(trans.amountPaidMinor)}</td>
                            <td>
                              ₦{CurrencyFormatter(trans.amountRemainingMinor)}
                            </td>
                          </tr>
                        )
                      )}

                    {portfolio &&
                      portfolio.susbscriptionTransactions.length === 0 && (
                        <tr>
                          <td colSpan={3} className="text-center">
                            No transaction found
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EstateSubscriberPortfolioDetails;
