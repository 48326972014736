import React, { useState, useEffect } from "react";
import "./EstateSubscriberProjects.scss";
import { ProjectItem } from "../../Components/Index";
import projectService from "../../../../../../Services/projectService";
import { projectDetailsType } from "../../../../../../Models/projectType";
import { HorizontalLoader, Pagination } from "../../../../../../Components";

const EstateSubscriberProjects = () => {
  const [projects, setProjects] = useState<Array<projectDetailsType>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);

  // eslint-disable-next-line no-unused-vars
  const [paginationData, setPaginationData] = useState<{
    pageNumber: number;
    pageSize: number;
    total: number;
  }>({
    pageNumber: 1,
    pageSize: 20,
    total: 1,
  });

  const getPopularProject = async () => {
    setIsLoading(true);
    await projectService.getUnSubscribedProjects(pageNumber).then(
      (response) => {
        setProjects(response.data.data.dataset);
        setPaginationData({
          pageNumber: response.data.data?.pageNumber,
          pageSize: response.data.data?.pageSize,
          total: response.data.data?.total,
        });
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    getPopularProject();
  }, [pageNumber]);

  return (
    <div className="EstateSubscriberProjects mb-4">
      <div className="container">
        <div className="row mt-4 h-100">
          <div className="col-12 col-md-6 my-auto">
            <div className="page-title">
              <h2>All Projects</h2>
              <div className="description">
                Here is a list of all available projects
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 my-auto d-none">
            <div className="seach-field">
              <input
                type="text"
                className="search-input form-control"
                placeholder="Search project type, location or something..."
              />
              <svg
                className="search-icon"
                width="14"
                height="14"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.6752 11.5657C9.3418 13.8991 5.55859 13.8991 3.22518 11.5657C0.891767 9.23226 0.891767 5.44905 3.22518 3.11564C5.55859 0.782229 9.3418 0.782229 11.6752 3.11564C14.0086 5.44905 14.0086 9.23226 11.6752 11.5657ZM11.6752 11.5657L16.4128 16.3033"
                  stroke="#868484"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
          <div className="col-4 col-lg-2 my-auto d-none">
            <button
              className="btn btn-filter dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={() => alert("Disabled")}
            >
              <svg
                className="btn-icon"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9404 22.6516C10.4604 22.6516 9.99039 22.5316 9.55039 22.2916C8.67039 21.8016 8.14039 20.9116 8.14039 19.9116V14.6116C8.14039 14.1116 7.81039 13.3616 7.50039 12.9816L3.76039 9.02156C3.13039 8.39156 2.65039 7.31156 2.65039 6.50156V4.20156C2.65039 2.60156 3.86039 1.35156 5.40039 1.35156H18.6004C20.1204 1.35156 21.3504 2.58156 21.3504 4.10156V6.30156C21.3504 7.35156 20.7204 8.54156 20.1304 9.13156L15.8004 12.9616C15.3804 13.3116 15.0504 14.0816 15.0504 14.7016V19.0016C15.0504 19.8916 14.4904 20.9216 13.7904 21.3416L12.4104 22.2316C11.9604 22.5116 11.4504 22.6516 10.9404 22.6516ZM5.40039 2.85156C4.70039 2.85156 4.15039 3.44156 4.15039 4.20156V6.50156C4.15039 6.87156 4.45039 7.59156 4.83039 7.97156L8.64039 11.9816C9.15039 12.6116 9.65039 13.6616 9.65039 14.6016V19.9016C9.65039 20.5516 10.1004 20.8716 10.2904 20.9716C10.7104 21.2016 11.2204 21.2016 11.6104 20.9616L13.0004 20.0716C13.2804 19.9016 13.5604 19.3616 13.5604 19.0016V14.7016C13.5604 13.6316 14.0804 12.4516 14.8304 11.8216L19.1104 8.03156C19.4504 7.69156 19.8604 6.88156 19.8604 6.29156V4.10156C19.8604 3.41156 19.3004 2.85156 18.6104 2.85156H5.40039Z"
                  fill="#868484"
                />
                <path
                  d="M5.99968 10.7473C5.85968 10.7473 5.72968 10.7073 5.59968 10.6373C5.24968 10.4173 5.13968 9.94733 5.35968 9.59733L10.2897 1.69733C10.5097 1.34733 10.9697 1.23733 11.3197 1.45733C11.6697 1.67733 11.7797 2.13733 11.5597 2.48733L6.62968 10.3873C6.48968 10.6173 6.24968 10.7473 5.99968 10.7473Z"
                  fill="#868484"
                />
              </svg>
              Filter
            </button>
            <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm d-none">
              <li>
                <a className="dropdown-item" href="#">
                  Paid Orders
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  PLP Orders
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Pay on Delivery
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {isLoading && <HorizontalLoader></HorizontalLoader>}

      <div className="container mt-5">
        <div className="row">
          {!isLoading &&
            projects &&
            projects.map((project: projectDetailsType, index) => (
              <div key={index} className="col-sm-6 col-lg-4 col-xl-4">
                <ProjectItem project={project}></ProjectItem>
              </div>
            ))}
        </div>

        {!isLoading && (!projects || projects.length === 0) && (
          <div className="text-center mt-4 fw-light text-danger">
            No project available at the moment, please check back later.
          </div>
        )}

        {paginationData.total > 1 && (
          <div className="pagination w-100 d-flex justify-content-center">
            {paginationData.total && (
              <Pagination
                className="pagination-bar ps-0"
                currentPage={Number(paginationData.pageNumber)}
                totalCount={
                  Number(paginationData.total) * Number(paginationData.pageSize)
                }
                pageSize={Number(paginationData.pageSize)}
                onPageChange={(page: React.SetStateAction<number>) =>
                  setPageNumber(page)
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EstateSubscriberProjects;
