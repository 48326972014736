import { FC, useState } from "react";
import "./ContactDetails.scss";
import { useForm } from "react-hook-form";
import { Logger } from "../../../../../../../Helpers/Logger";
import { EstateAccountNameChangeRequest } from "../../../../EstateManagers/Components/Index";

interface propType {
  emailAddress: string;
  phoneNumber: string;
  module?: string;
}

type formProp = {
  email: string;
  phoneNumber: string;
};

const ContactDetails: FC<propType> = ({
  emailAddress,
  phoneNumber,
  module,
}) => {
  const [submit, setSubmit] = useState(false);
  const [showNameChange, setShowNameChange] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formProp>();

  const onSubmit = handleSubmit((data) => processProfile(data));

  const processProfile = (data: { [x: string]: any }) => {
    Logger(data);
    setSubmit(true);
  };

  return (
    <div className="buyer-profile-contact-details">
      <form onSubmit={onSubmit}>
        <div className="form-input">
          <label htmlFor="email">Email Address</label>
          <input
            type="text"
            id="email"
            defaultValue={emailAddress}
            className="form-control"
            {...register("email")}
          />
          {errors.email && (
            <div className="text-danger">{errors.email.message}</div>
          )}
        </div>
        <div className="form-input">
          <label htmlFor="email">Phone Number</label>
          <input
            type="text"
            id="phoneNumber"
            defaultValue={phoneNumber}
            className="form-control"
            {...register("phoneNumber")}
          />
          {errors.phoneNumber && (
            <div className="text-danger">{errors.phoneNumber.message}</div>
          )}
        </div>
        <div className="form-input">
          {!submit && (
            <button
              className={module && module === "estate" ? "btn-estate" : "btn"}
            >
              <svg
                className="me-2"
                width="8"
                height="15"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 5.66406C4.10457 5.66406 5 4.76863 5 3.66406C5 2.55949 4.10457 1.66406 3 1.66406C1.89543 1.66406 1 2.55949 1 3.66406C1 4.76863 1.89543 5.66406 3 5.66406Z"
                  stroke={module && module === "estate" ? "#3A0CA3" : "#F5A623"}
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 5.66406V8.66406"
                  stroke={module && module === "estate" ? "#3A0CA3" : "#F5A623"}
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Request Update
            </button>
          )}
          {submit && (
            <button
              className={module && module === "estate" ? "btn-estate" : "btn"}
            >
              <span className="spinner-border spinner-border-sm me-2"></span>
              Please wait
            </button>
          )}
        </div>
      </form>

      <div className="form-input">
        {!submit && (
          <button
            className={
              module && module === "estate" ? "btn-estate" : "btn d-none"
            }
            onClick={() => setShowNameChange(true)}
          >
            <svg
              className="me-2"
              width="8"
              height="15"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 5.66406C4.10457 5.66406 5 4.76863 5 3.66406C5 2.55949 4.10457 1.66406 3 1.66406C1.89543 1.66406 1 2.55949 1 3.66406C1 4.76863 1.89543 5.66406 3 5.66406Z"
                stroke={module && module === "estate" ? "#3A0CA3" : "#F5A623"}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3 5.66406V8.66406"
                stroke={module && module === "estate" ? "#3A0CA3" : "#F5A623"}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Request Name Change
          </button>
        )}
        {submit && (
          <button
            className={
              module && module === "estate" ? "btn-estate" : "btn d-none"
            }
          >
            <span className="spinner-border spinner-border-sm me-2"></span>
            Please wait
          </button>
        )}
      </div>

      <EstateAccountNameChangeRequest
        showModal={showNameChange}
        handleClose={() => {
          setShowNameChange(!showNameChange);
        }}
      ></EstateAccountNameChangeRequest>
    </div>
  );
};

export default ContactDetails;
