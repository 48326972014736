import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import BuyerPageTitle from "../../../../../../Components/Buyer/BuyerPageTitle/BuyerPageTitle";
import "./BuyerQuoteRequest.scss";

const BuyerQuoteRequest = () => {
  return (
    <div className="buyer-quote-request">
      <div className="container mt-4">
        <BuyerPageTitle
          title={"My Quote Requests"}
          parent={null}
          parentUrl={null}
        ></BuyerPageTitle>

        <ul className="nav sub-nav">
          <li className="nav-item">
            <NavLink
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
              to="active-request"
            >
              Active request
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
              to="pending-response"
            >
              Pending response
            </NavLink>
          </li>
        </ul>
        <Outlet />
      </div>
    </div>
  );
};

export default BuyerQuoteRequest;
