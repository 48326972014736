import axios from "axios";

import { AxiosInterceptor } from "../Config/AxiosInterceptor";
import { investorCreatType } from "../Models/investorType";
import { processMandateRequestType } from "../Pages/Frontend/Interface/EstateManagers/Interface/ApproveMandate/ApproveMandate";

AxiosInterceptor();

const investorService = {
  async index(status: string, pageNumber: number, sortOrder: string) {
    return axios.get(
      `/project/subscriptions?status=${status}&pageNumber=${pageNumber}&sortOrder=${sortOrder}`
    );
  },

  async create(data: investorCreatType) {
    return axios.post("/estatedeveloper/addinvestor", data);
  },

  async getSingle(projectUuid: string | undefined) {
    return axios.get(`/project/subscriptions/${projectUuid}`);
  },

  file: async (Uuid: string, data: FormData, imgType: string) => {
    return await axios.put(
      `/upload?fileUploadCategory=${imgType}&entityUuid=${Uuid}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  async getTransactions(pageNumber: number, sortOrder: string) {
    return await axios.get(
      `/investor/projecttransactions?pageNumber=${pageNumber}&sortOrder=${sortOrder}`
    );
  },

  async getTransactionsDetails(uuid: string | undefined) {
    return await axios.get(`investor/projecttransactions/${uuid}`);
  },

  async getRecurrentPayment() {
    return await axios.get(
      `/investor/projecttransactions/pendingrecurrentpayment`
    );
  },

  async payRecurrentPayment(
    transactionUuid: string | undefined,
    totalCost: number | undefined,
    paymentType: string
  ) {
    return await axios.post(
      `/investor/projecttransactions/payment/${transactionUuid}/${paymentType}`,
      { totalCost }
    );
  },

  async mandateRequest(data: { projectSubscriptionUuid: string }) {
    return await axios.post(`/estatedeveloper/mandate-request`, data);
  },

  async investorMandateRequest() {
    return await axios.get(`/investor/mandate-request`);
  },

  async investorMandateRequestSingle(mandateUuid: string) {
    return await axios.get(`/investor/mandate-request/${mandateUuid}`);
  },

  async bankList() {
    return await axios.get("/directdebit/banklist");
  },

  async processMandateRequest(data: processMandateRequestType) {
    return await axios.post("/directdebit/process-mandate-request", data);
  },
};
export default investorService;
