import React from "react";
import "./Header.scss";
import { NavMenu, SubNavMenu } from "..";

const Header = () => {
  return (
    <header className="d-none d-lg-block">
      <NavMenu></NavMenu>
      <SubNavMenu></SubNavMenu>
    </header>
  );
};

export default Header;
