import React, { useEffect, useState, Key } from "react";
import "./EstateManagersMortgageCard.scss";
import { ActivateCard, PageNotice, Statistics } from "../../Components/Index";
import MortgageWalletInformation from "../../Components/EstateDashboard/WalletInformation/MortgageWalletInformation";
import investorService from "../../../../../../Services/investorService";
import { investorType } from "../../../../../../Models/investorType";
import HorizontalLoader from "../../../../../../Components/Loader/HorizontalLoader/HorizontalLoader";
import { CbdModal } from "../../../../../../Components";
import bankingFinanceService from "../../../../../../Services/bankingFinanceService";
import toast, { Toaster } from "react-hot-toast";

const EstateManagersMortgageCard: React.FC = () => {
  const [sortOrder] = useState("DESC");
  const [status] = useState("ALL");
  const [pageNumber] = useState(1);
  const [investor, setInvestor] = useState<Array<investorType>>();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [activatedStatus, setActivatedStatus] = useState<boolean>(true);

  const getInvestor = async () => {
    setLoadingRequest(true);
    await investorService.index(status, pageNumber, sortOrder).then(
      (response) => {
        setLoadingRequest(false);

        setInvestor(response.data.data.dataset);
      },
      (error) => {
        setLoadingRequest(false);
        console.log("projectService error", error);
      }
    );
  };

  const [myBalance, setMyBalance] = useState<{
    currency: string;
    currencySymbol: string;
    amountMajor: Number;
  }>({
    currency: "",
    currencySymbol: "",
    amountMajor: 0,
  });

  useEffect(() => {
    getInvestor();
  }, [status]);

  const getMyBalance = async () => {
    setActivatedStatus(true);
    await bankingFinanceService.getMyMortgateBalance().then(
      (res: any) => {
        setMyBalance(res.data.data);
        setActivatedStatus(true);
      },
      (error: any) => {
        setActivatedStatus(false);
        toast.error(error.response.data.error, {
          duration: 20000,
          className: "custom-alert bg-white text-dark",
          position: "top-right",
        });
      }
    );
  };

  useEffect(() => {
    getMyBalance();
  }, []);

  return (
    <div className="EstateManagersMortgageCard">
      <div className="container">
        {activatedStatus === false && (
          <PageNotice
            noticeType="warning"
            message="Your account has limited functionality at the moment, allowing purchases only. 
                        To unlock the platform's full potential, kindly complete the verification process."
            closable={false}
            buttonLabel="Activate Card"
            action={() => setShow(true)}
          />
        )}

        <div className="row h-100 align-items-end">
          <div className="col-md-7 mb-auto">
            <div className="card ps-0 border-0">
              <div className="card-body pb-0">
                <div className="header-title mt-md-5">
                  <h2>Card Overview</h2>
                  <div className="subtext">
                    Manage mortgage card information
                  </div>
                </div>
                <Statistics></Statistics>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <MortgageWalletInformation
              myBalance={myBalance}
            ></MortgageWalletInformation>
          </div>
        </div>
      </div>

      <div className="subscriber-list container mt-5">
        <div className="row mb-2">
          <div className="col-6 text-start">
            <div className="table-title">Subscribers</div>
          </div>
          <div className="col-6 text-end"></div>
        </div>
        <div className="card">
          <div className="card-body p-0">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <td>Investor</td>
                    <td>Phone Number</td>
                    <td>Status</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {!loadingRequest &&
                    investor &&
                    investor.map((res: investorType, index: Key) => (
                      <tr key={index}>
                        <td>
                          {res.investorPublicProfile.firstName}{" "}
                          {res.investorPublicProfile.lastName}
                        </td>
                        <td>{res.investorPublicProfile.phoneNumber}</td>
                        <td>
                          <span className="status">Inactive</span>
                        </td>
                        <td>
                          <button className="btn btn-resend">
                            Resend Link
                          </button>
                        </td>
                      </tr>
                    ))}
                  {loadingRequest === false && !investor && (
                    <tr>
                      <td colSpan={5}>
                        <div className="text-secondary text-center fw-bold">
                          No subscriber found
                        </div>
                      </td>
                    </tr>
                  )}
                  {loadingRequest === true && (
                    <tr>
                      <td colSpan={5}>
                        <div className="text-secondary text-center fw-bold">
                          <HorizontalLoader></HorizontalLoader>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <CbdModal showModal={show} closeModal={() => setShow(false)} title={""}>
        <ActivateCard handleHide={() => setShow(false)}></ActivateCard>
      </CbdModal>
      <Toaster></Toaster>
    </div>
  );
};

export default EstateManagersMortgageCard;
