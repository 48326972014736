import React, { useState } from "react";
import "./Slider.scss";
import slider1 from "../../../../../Assets/Images/slider/slider1.png";
import slider2 from "../../../../../Assets/Images/slider/slider2.png";
import enterpriseslider from "../../../../../Assets/Images/enterprise/enterprise-slider.png";
import estateslider from "../../../../../Assets/Images/estate/estate-slider1.jpg";

import ImageSrc from "../../../../../Components/ImageSrc/ImageSrc";
import { Link } from "react-router-dom";
import B360deg from "../../../../../Assets/Images/B360deg.svg";
import { useAuth } from "../../../../../Components/AuthProvider/AuthProvider";
import LastOrder from "./LastOrder/LastOrder";

const Slider = () => {
  const { user } = useAuth();
  const [lastOrderStatus, setLastOrderStatus] = useState(false);
  const [lastOrderRequest, setLastOrderRequest] = useState(true);

  const handleDataFromLastOrder = (data: boolean) => {
    setLastOrderStatus(data);
  };

  const handleRequestFromLastOrder = (data: boolean) => {
    setLastOrderRequest(data);
  };

  return (
    <div className="home-slider">
      <div className="container h-100">
        <div
          id="carouselExampleIndicators"
          className="carousel slide h-100 carousel-fade"
          data-bs-ride="carousel"
          data-pause="false"
        >
          {lastOrderStatus === false && lastOrderRequest === false ? (
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="4"
                aria-label="Slide 5"
              ></button>
            </div>
          ) : (
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-current="true"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="4"
                aria-label="Slide 5"
              ></button>
            </div>
          )}
          <div className="carousel-inner">
            <LastOrder
              sendDataToParent={handleDataFromLastOrder}
              setLastOrderRequest={handleRequestFromLastOrder}
            ></LastOrder>
            {/* <div className={lastOrderStatus ===false  && lastOrderRequest === false? "carousel-item active": "carousel-item"}> */}
            <div
              className={
                lastOrderStatus === false
                  ? "carousel-item active"
                  : "carousel-item"
              }
            >
              <div className="card p-0 border-0">
                <div className="card-body p-0">
                  <div className="row h-100">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 my-auto p-5 text-col p-0">
                      <h2>
                        Africa’s Building
                        <br /> Materials Marketplace
                      </h2>
                      <div className="slider-desc">
                        CinderBuild brings bulk off-takers and suppliers onto
                        one collaborative platform streamlining the materials
                        procurement process from requisition to delivery.
                      </div>

                      {user ? (
                        <Link to="/products">
                          <button className="btn btn-getstarted mt-3">
                            {" "}
                            Shop Now{" "}
                          </button>
                        </Link>
                      ) : (
                        <Link to="/register">
                          <button className="btn btn-getstarted mt-3">
                            {" "}
                            Get Started{" "}
                          </button>
                        </Link>
                      )}
                    </div>
                    <div className="m-0 col-12 col-sm-12 col-md-12 col-lg-5 my-auto sliderImage">
                      <ImageSrc
                        src={slider1}
                        alt={"CinderBuild"}
                        title={"CinderBuild"}
                        width={"w-100"}
                      ></ImageSrc>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="card p-0 border-0">
                <div className="card-body p-0">
                  <div className="row h-100">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 my-auto p-5 text-col p-0">
                      <h2>CinderBuild Affiliate</h2>
                      <div className="slider-desc">
                        The journey to becoming your own boss starts now! Stand
                        a chance to make as much as NGN 500,000 monthly.
                      </div>

                      {user ? (
                        <Link to="/products">
                          <button className="btn btn-getstarted mt-3">
                            {" "}
                            Shop Now{" "}
                          </button>
                        </Link>
                      ) : (
                        <Link to="/register">
                          <button className="btn btn-getstarted mt-3">
                            {" "}
                            Get Started{" "}
                          </button>
                        </Link>
                      )}
                    </div>
                    <div className="m-0 col-12 col-sm-12 col-md-12 col-lg-5 my-auto sliderImage">
                      <ImageSrc
                        src={slider2}
                        alt={"CinderBuild"}
                        title={"CinderBuild"}
                        width={"w-100"}
                      ></ImageSrc>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="card p-0 border-0">
                <div className="card-body p-0">
                  <div className="row h-100">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 my-auto p-5 text-col p-0">
                      <h2>
                        Bulk Procurement solution for high-budget projects.
                      </h2>
                      <div className="slider-desc">
                        CinderBuild for Corporates allows companies store
                        purchased items virtually on the platform and draw down
                        on these as needed, specifying delivery location at each
                        instance.
                      </div>
                      <Link to="/enterprise-home">
                        <button className="btn btn-getstarted mt-3">
                          Get Started
                        </button>
                      </Link>
                    </div>
                    <div className="m-0 col-12 col-sm-12 col-md-12 col-lg-5 my-auto sliderImage">
                      <ImageSrc
                        src={enterpriseslider}
                        alt={"CinderBuild"}
                        title={"CinderBuild"}
                        width={"w-100"}
                      ></ImageSrc>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="card p-0 border-0">
                <div className="card-body p-0">
                  <div className="row h-100">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 my-auto p-5 text-col p-0">
                      <h2> CinderBuild Mortgage Solution</h2>
                      <div className="slider-desc">
                        Unlock your dream home faster with an ultra-modern
                        mortgage solution tailored for Africa&apos;s elite
                        professionals.
                      </div>
                      <Link to="/estate-home">
                        <button className="btn btn-getstarted mt-3">
                          Get Started
                        </button>
                      </Link>
                    </div>
                    <div className="m-0 col-12 col-sm-12 col-md-12 col-lg-5 my-auto sliderImage">
                      <ImageSrc
                        src={estateslider}
                        alt={"CinderBuild"}
                        title={"CinderBuild"}
                        width={"w-100"}
                      ></ImageSrc>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="card p-0 border-0">
                <div className="card-body p-0">
                  <div className="row h-100">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 my-auto p-5 text-col p-0">
                      <h2> Builder 360™</h2>
                      <div className="slider-desc">
                        Your purchases on CinderBuild are rewarded. The more you
                        purchase, the more you unlock advanced business
                        solutions that increase your market share, expand your
                        reach and grow your business exponentially.
                      </div>
                      <Link to="/builder360">
                        <button className="btn btn-getstarted mt-3">
                          Know More
                        </button>
                      </Link>
                    </div>
                    <div className="m-0 col-12 col-sm-12 col-md-12 col-lg-5 my-auto sliderImage">
                      <ImageSrc
                        src={B360deg}
                        alt={"CinderBuild"}
                        title={"CinderBuild"}
                        width={"b360-img"}
                      ></ImageSrc>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev d-none"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next my-auto me-5 carousel-nav-right shadow d-none d-lg-block"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="d-icon d-arrowhead-right islarge-s text-secondary"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Slider;
