import React, { FC, Key, useEffect, useState } from "react";
import "./CreateProject.scss";
import { VerificationDocumentUploadCard } from "../../Index";
import { useForm } from "react-hook-form";
import {
  projectDetailsType,
  projectType,
} from "../../../../../../../Models/projectType";
import stateNg from "../../../../../../../Data/stateNG.json";
import projectService from "../../../../../../../Services/projectService";
import { Logger } from "../../../../../../../Helpers/Logger";
import toast, {
  Renderable,
  Toast,
  Toaster,
  ValueFunction,
} from "react-hot-toast";

interface propsType {
  handleClose: () => void;
  projectData: projectDetailsType | undefined;
}
const EditProject: FC<propsType> = ({ handleClose, projectData }) => {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<projectType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [images, setImages] = useState<any>([]);
  const onSubmit = handleSubmit((data) => processForm(data));

  const processForm = async (data: projectType) => {
    setLoading(true);
    await projectService.update(projectData?.projectUuid, data).then(
      (response: { data: { projectUuid: string } }) => {
        console.log("processForm response", response);
        if (images.length > 0) {
          startUploading(response.data.projectUuid);
        } else {
          setLoading(false);
          toast.success("Project saved succesfully", {
            duration: 20000,
            className: "custom-alert bg-white text-dark",
            position: "top-right",
          });
          handleClose();
        }
      },
      (error: {
        response: {
          data: {
            error: Renderable | string | ValueFunction<Renderable, Toast>;
          };
        };
      }) => {
        setLoading(false);
        toast.error(error.response.data.error, {
          duration: 20000,
          className: "custom-alert bg-white text-dark",
          position: "top-right",
        });

        console.log("processForm error", error);
      }
    );
  };

  const changeHandler = async (event: any, fileName: any) => {
    Logger("fileName", fileName);
    setImages([...images, { file: event.target.files[0], name: fileName }]);
  };

  const checkIfExist = (fName: string) => {
    const found = images.some((el: { name: any }) => el.name === fName);
    return found;
  };

  const startUploading = async (uuid: string) => {
    // eslint-disable-next-line no-unused-vars
    for (const [index, currentProductImage] of images.entries()) {
      const formData = new FormData();
      Logger("PRODUCT FINANCE FILE", currentProductImage.file);
      // Update the formData object
      formData.append("file", currentProductImage.file);

      await projectService.file(uuid, formData, currentProductImage.name).then(
        (res: any) => {
          setLoading(false);
          toast.success("Project saved succesfully", {
            duration: 20000,
            className: "custom-alert bg-white text-dark",
            position: "top-right",
          });
          handleClose();
        },
        (error: any) => {
          console.log("image upload error", error);
          setLoading(false);
          toast.error(error.response.data.error, {
            duration: 20000,
            className: "custom-alert bg-white text-dark",
            position: "top-right",
          });
        }
      );
    }
  };

  useEffect(() => {
    if (projectData) {
      setValue("name", projectData.name);
      setValue("address", projectData.address);
      setValue("costPerSlot", projectData.costPerSlot);
      setValue("details", projectData.details);
      setValue("duration", projectData.duration);
      setValue(
        "initialInvestmentPercentage",
        projectData.initialInvestmentPercentage
      );
      //   setValue("locationOnMap", projectData.locationOnMap)
      setValue("minimumNumberOfSlot", projectData.minimumNumberOfSlot);
      setValue("numberOfSlots", projectData.numberOfSlots);
      setValue("paymentPlan", projectData.paymentPlan);
      setValue("startDate", projectData.startDate);
      setValue("state", projectData.state);
      setValue("type", projectData.type);
    }
  }, [projectData]);

  return (
    <div className="CreateProject">
      <form onSubmit={onSubmit}>
        <div className="form-group mb-3">
          <label htmlFor="projectName">Project Name</label>
          <input
            type="text"
            id="projectName"
            className="form-control"
            {...register("name", {
              required: "This field is can not be empty",
            })}
            placeholder="Project Name"
          />
          {errors.name && (
            <span className="error text-danger">{errors.name.message}</span>
          )}
        </div>
        <div className="form-group mb-3">
          <label htmlFor="projectType">Project Type</label>
          <select
            id="projectType"
            className="form-control"
            placeholder="Project Name"
            {...register("type", {
              required: "This field is can not be empty",
            })}
          >
            <option>--choose--</option>
            <option value="Shop">Shop</option>
            <option value="Office Spaces">Office Spaces</option>
            <option value="Flats and Apartments">Flats and Apartments</option>
            <option value="Semi Detached Bungalow">
              Semi Detached Bungalow
            </option>
            <option value="Semi Detached Duplex">Semi Detached Duplex</option>
            <option value="Co-working Space">Co-working Space</option>
            <option value="Detached Bungalow">Detached Bungalow</option>
            <option value="Flats and Apartments">Flats and Apartments</option>
            {/* <option value="Min Price">Min Price</option>
            <option value="Max Price">Max Price</option> */}
            <option value="Semi Detached Bungalow">
              Semi Detached Bungalow
            </option>
            <option value="Semi Detached Duplex">Semi Detached Duplex</option>
            <option value="Co-working Space">Co-working Space</option>
            <option value="Detached Bungalow">Detached Bungalow</option>
            {/* <option value="SUJIMOTO">SUJIMOTO</option> */}
            {/* <option value="Featured Project">Featured Project</option> */}
            <option value="Shop In A Mall">Shop In A Mall</option>
            <option value="Self Contain">Self Contain</option>
            <option value="Mini Flats">Mini Flats</option>
            <option value="Detached Duplex">Detached Duplex</option>
            {/* <option value="Houses">Houses</option> */}
            <option value="Terraced Bungalow">Terraced Bungalow</option>
            <option value="Commercial Properties">Commercial Properties</option>
            <option value="Terraced Duplex">Terraced Duplex</option>
          </select>
          {errors.type && (
            <span className="error text-danger">{errors.type.message}</span>
          )}
        </div>
        <div className="form-group mb-3">
          <label htmlFor="projectDetails">Project Details</label>
          <textarea
            id="projectDetails"
            className="form-control"
            placeholder="Project Details"
            {...register("details", {
              required: "This field is can not be empty",
            })}
          />
          {errors.details && (
            <span className="error text-danger">{errors.details.message}</span>
          )}
        </div>

        <div className="form-group mb-3">
          <label htmlFor="costperunit">Cost Per Slot</label>
          <input
            type="text"
            id="costperunit"
            className="form-control"
            placeholder="Project Name"
            {...register("costPerSlot", {
              required: "This field is can not be empty",
            })}
          />
          {errors.costPerSlot && (
            <span className="error text-danger">
              {errors.costPerSlot.message}
            </span>
          )}
        </div>
        <div className="form-group mb-3">
          <label htmlFor="currentStage">Minimum Number Of Slot</label>
          <input
            type="text"
            id="currentStage"
            className="form-control"
            placeholder="Current Stage"
            {...register("minimumNumberOfSlot", {
              required: "This field is can not be empty",
            })}
          />
          {errors.minimumNumberOfSlot && (
            <span className="error text-danger">
              {errors.minimumNumberOfSlot.message}
            </span>
          )}
        </div>

        <div className="row">
          <div className="col-6">
            <div className="form-group mb-3">
              <label htmlFor="currentStage">Number of Units</label>
              <input
                type="text"
                id="currentStage"
                className="form-control"
                placeholder="Current Stage"
                {...register("numberOfSlots", {
                  required: "This field is can not be empty",
                })}
              />
              {errors.numberOfSlots && (
                <span className="error text-danger">
                  {errors.numberOfSlots.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="form-group mb-3">
              <label htmlFor="currentStage">Start date</label>
              <input
                type="date"
                id="currentStage"
                className="form-control"
                placeholder="Current Stage"
                {...register("startDate", {
                  required: "This field is can not be empty",
                })}
              />
              {errors.startDate && (
                <span className="error text-danger">
                  {errors.startDate.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="form-group mb-3">
              <label htmlFor="currentStage">Initial Payment Percentage</label>
              <input
                type="text"
                id="currentStage"
                className="form-control"
                placeholder="Current Stage"
                {...register("initialInvestmentPercentage", {
                  required: "This field is can not be empty",
                })}
              />
              {errors.initialInvestmentPercentage && (
                <span className="error text-danger">
                  {errors.initialInvestmentPercentage.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="form-group mb-3">
              <label htmlFor="currentStage">Duration</label>
              <input
                type="text"
                id="currentStage"
                className="form-control"
                placeholder="Current Stage"
                {...register("duration", {
                  required: "This field is can not be empty",
                })}
              />
              {errors.duration && (
                <span className="error text-danger">
                  {errors.duration.message}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="currentStage">Address</label>
          <input
            type="text"
            id="currentStage"
            className="form-control"
            placeholder="Current Stage"
            {...register("address", {
              required: "This field is can not be empty",
            })}
          />
          {errors.address && (
            <span className="error text-danger">{errors.address.message}</span>
          )}
        </div>

        {/* <div className='form-group mb-3'>
                    <label htmlFor="locationOnMap">Location On Map</label>
                    <input type="text" id="locationOnMap" className='form-control' placeholder='Current Stage'  {...register("locationOnMap", { required: "This field is can not be empty"})}/>
                    {errors.locationOnMap && <span className='error text-danger'>{errors.locationOnMap.message}</span>}
               </div> */}

        <div className="form-group mb-3">
          <label htmlFor="currentStage">State</label>
          <select
            id="currentStage"
            className="form-control"
            placeholder="Current Stage"
            {...register("state", {
              required: "This field is can not be empty",
            })}
          >
            <option value="">--choose--</option>
            {stateNg[2].data?.map((state, index: Key) => (
              <option value={state.name} key={index}>
                {state.name}
              </option>
            ))}
          </select>
          {errors.state && (
            <span className="error text-danger">{errors.state.message}</span>
          )}
        </div>

        <VerificationDocumentUploadCard
          title={"Project Image"}
          description={"Project Image"}
          changeHandler={(event: any, filename: string) =>
            changeHandler(event, filename)
          }
          name={"project_image"}
          doc={"PROJECT_IMAGES"}
          checkIfExist={checkIfExist}
        ></VerificationDocumentUploadCard>

        <div className="form-group mb-3">
          <button type="submit" className="btn create-btn" disabled={false}>
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}{" "}
            Update Details
          </button>
        </div>
      </form>

      <Toaster></Toaster>
    </div>
  );
};

export default EditProject;
