import React, { useEffect, useState } from "react";
import "./ApproveMandate.scss";
import { FieldValues, useForm } from "react-hook-form";
import investorService from "../../../../../../Services/investorService";
import { GetLength } from "../../../../../../Helpers/getLength";
import bankingFinanceService from "../../../../../../Services/bankingFinanceService";
import toast, { Toaster } from "react-hot-toast";
import { mandateType } from "../../../../../../Models";
import { useNavigate, useParams } from "react-router-dom";
import Swal, { SweetAlertResult } from "sweetalert2";

export interface processMandateRequestType {
  mandateUuid: string | undefined;
  bvn: number;
  accountNumber: number;
  nipCode: string | undefined;
  bankCode: string | undefined;
  projectSubscriptionUuid: string | undefined;
  address: string;
  completedNow: boolean;
}

const ApproveMandate = () => {
  const { mandateUuid } = useParams();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{
    bvn: string;
    address: string;
    accountNumber: string;
    bankName: string;
  }>();
  const [bankList, setBankList] = useState<
    Array<{
      name: string;
      bankCode: string;
      nipCode: string;
      directDebit: boolean;
    }>
  >();

  const [nameSearchRequest, setNameSearchRequest] = useState(false);

  const [savingBankDetails, setSavingBankDetails] = useState(false);

  const [selectedBankObj, setSelectedBank] = useState<{
    name: string;
    bank_code: string;
    nip_code: string;
    direct_debit: boolean;
  }>();
  // eslint-disable-next-line no-unused-vars
  const [myAccountNumber, setMyAccountNumber] = useState();
  // eslint-disable-next-line no-unused-vars
  const [myAccountDetails, setMyAccountDetails] = useState<any>(null);

  const getBankList = async () => {
    await investorService.bankList().then((response) => {
      console.log("bankList", response.data.data);
      setBankList(response.data.data);
    });
  };

  useEffect(() => {
    getBankList();
  }, []);

  const selectBankNumber = (e: { target: { value: any } }) => {
    setMyAccountNumber(e.target.value);
    if (GetLength(e.target.value) >= 10) {
      setNameSearchRequest(true);
      bankingFinanceService
        .VerifyAccount({
          accountNumber: e.target.value,
          bankCode: selectedBankObj?.bank_code,
        })
        .then(
          (res) => {
            // console.log("NAME ENQUIRY", res.data.data)
            setNameSearchRequest(false);
            setMyAccountDetails(res.data.data);
          },
          (error) => {
            setNameSearchRequest(false);
            setMyAccountDetails(false);
            toast.error(error.message, {
              duration: 20000,
              className: "bg-white text-secondary",
            });
            console.log(error.response);
          }
        );
    }
  };

  const selectedBank = (e: any) => {
    setSelectedBank(JSON.parse(e.target.value));
  };

  const [mandateDetails, setMandateDetails] = useState<mandateType>();

  const getMyMandateDetails = async (mandateUuid: string) => {
    await investorService.investorMandateRequestSingle(mandateUuid).then(
      (result) => {
        console.log("getMyMandateDetails", result.data.data);
        setMandateDetails(result.data.data);
      },
      (error) => {
        toast.error(error.message, {
          duration: 20000,
          className: "bg-white text-secondary",
        });
        console.log("getMyMandateDetails error", error);
      }
    );
  };

  useEffect(() => {
    mandateUuid && getMyMandateDetails(mandateUuid);
  }, [mandateUuid]);

  const submit = async (data: FieldValues) => {
    setSavingBankDetails(true);

    let timerInterval: any;

    await investorService
      .processMandateRequest({
        mandateUuid: mandateDetails?.mandateUuid,
        bvn: data.bvn,
        accountNumber: data.accountNumber,
        nipCode: selectedBankObj?.nip_code,
        bankCode: selectedBankObj?.bank_code,
        projectSubscriptionUuid:
          mandateDetails?.projectSubscriptions.projectSubscriptionUuid,
        address: data.address,
        completedNow: true,
      })
      .then(
        (response) => {
          setSavingBankDetails(false);
          console.log("response.data.message", response);

          Swal.fire({
            title: "Attention!",
            html: `${response.data.data.message}.  <b></b> seconds remaining.`,
            timer: 900000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const timer = Swal.getPopup()?.querySelector("b");
              if (timer) {
                timerInterval = setInterval(() => {
                  timer.textContent = `${(
                    Number(Swal.getTimerLeft()) / 60000
                  ).toFixed(2)}`;
                }, 100);
              }
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result: SweetAlertResult) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.fire({
                title: "Done!",
                text: "An activation mail will be sent to your registered email address.",
                icon: "success",
                confirmButtonText: "Ok",
              }).then((result: { isConfirmed: boolean }) => {
                if (result.isConfirmed) {
                  navigate("/estate-managers/my-account", { replace: true });
                }
              });
            }
          });
        },
        (error) => {
          console.log(error.response);
          setSavingBankDetails(false);
          toast.error(error.response.data.error, {
            duration: 20000,
            className: "bg-white text-dark",
          });
        }
      );
  };

  return (
    <React.Fragment>
      <div className="ApproveMandate">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card border-0 my-5">
                <div className="card-header bg-white border-0 pt-4 fw-bold">
                  Direct Debit Approval Form
                </div>
                <div className="card-body py-5">
                  <form onSubmit={handleSubmit((data) => submit(data))}>
                    <div className="form-group">
                      <label htmlFor="">Bank</label>
                      <select
                        className="form-control"
                        {...register("bankName", {
                          required: "This field is required",
                          onChange: (e) => {
                            selectedBank(e);
                          },
                        })}
                      >
                        <option value="">--option--</option>
                        {bankList &&
                          bankList?.map((res, index: React.Key) => (
                            <option key={index} value={JSON.stringify(res)}>
                              {res.name}
                            </option>
                          ))}
                      </select>
                      {errors.bankName && (
                        <small className="text-danger">
                          {errors.bankName.message}
                        </small>
                      )}
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="">Account Number</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("accountNumber", {
                          required: "This field is required",
                          onChange: (e) => selectBankNumber(e),
                        })}
                        placeholder="Account Number"
                        disabled={!selectedBankObj || nameSearchRequest}
                      />

                      {nameSearchRequest && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      {myAccountDetails !== false &&
                        myAccountDetails !== null && (
                          <span className="text-success fw-bolder">
                            {myAccountDetails.account_name}
                          </span>
                        )}
                      {myAccountDetails === false && (
                        <span className="text-danger">
                          Invalid account number
                        </span>
                      )}
                      {errors.accountNumber && (
                        <small className="text-danger">
                          {errors.accountNumber.message}
                        </small>
                      )}
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="">Your BVN</label>
                      <input
                        className="form-control"
                        {...register("bvn", {
                          required: "This field is required",
                        })}
                      />
                      {errors.bvn && (
                        <small className="text-danger">
                          {errors.bvn.message}
                        </small>
                      )}
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="">Your Address</label>
                      <textarea
                        className="form-control"
                        {...register("address", {
                          required: "This field is required",
                        })}
                      ></textarea>
                      {errors.address && (
                        <small className="text-danger">
                          {errors.address.message}
                        </small>
                      )}
                    </div>

                    <div className="form-group mt-3">
                      <button className="btn" disabled={savingBankDetails}>
                        {savingBankDetails && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster></Toaster>
    </React.Fragment>
  );
};

export default ApproveMandate;
