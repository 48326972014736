import React, { ChangeEvent, useEffect, useState } from "react";
import "./EstateSubscriberRecurrentPaymentDetails.scss";
import { HorizontalLoader } from "../../../../../../../Components";
import toast, { Toaster } from "react-hot-toast";
import { useParams, Link, useNavigate } from "react-router-dom";
import investorService from "../../../../../../../Services/investorService";
import { transactionType } from "../../../../../../../Models/transactionType";
import { CurrencyFormatter } from "../../../../../../../Helpers/CurrencyFormatter";
import { WalletInformation } from "../../../Components/Index";
import Swal, { SweetAlertResult } from "sweetalert2";

const EstateSubscriberRecurrentPaymentDetails = () => {
  const [balance, setBalance] = useState(0);

  const { uuid } = useParams<{ uuid: string | undefined }>();

  const [loadingRequest, setLoadingRequest] = useState(false);

  const [transaction, setTransaction] = useState<transactionType>();

  const [manualAmountToBePaid, setManualAmountToBePaidHandler] = useState(0);
  const amountToBePaid =
    manualAmountToBePaid === 0
      ? transaction?.amountPaidMinor
      : manualAmountToBePaid;
  const amountToBePayable = transaction?.amountPaidMinor;
  const remainingBalanceRaw = transaction?.amountRemainingMinor;

  const getTransaction = async () => {
    setLoadingRequest(true);
    await investorService.getTransactionsDetails(uuid).then(
      (response) => {
        setLoadingRequest(false);
        setTransaction(response.data.data);
      },
      (error) => {
        setLoadingRequest(false);
        toast.error(error.response.data.error, {
          duration: 20000,
          className: "bg-white text-dark",
          position: "top-right",
        });

        console.log("projectService error", error);
      }
    );
  };

  useEffect(() => {
    getTransaction();
  }, [uuid]);

  const [request, setRequest] = useState(false);
  const [requestMono, setRequestMono] = useState(false);

  const [terms, setTerm] = useState(true);
  const navigate = useNavigate();

  const createSubscription = async () => {
    setRequest(true);
    await investorService
      .payRecurrentPayment(transaction?.uuid, amountToBePaid, "WALLET")
      .then(
        (response) => {
          setRequest(false);
          Swal.fire({
            title: "Congratulations!",
            text: "You have successfully paid for this subscription",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result: { isConfirmed: boolean }) => {
            if (result.isConfirmed) {
              navigate("/estate-managers/subscriber-recurrent-payment", {
                replace: true,
              });
            }
          });

          console.log("Create Subscription response", response);
        },
        (error) => {
          setRequest(false);
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
          swalWithBootstrapButtons.fire({
            title: "Oops!",
            text: error.response.data.error,
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "close",
            reverseButtons: true,
          });
          console.log("project subscription Service error", error);
        }
      );
  };

  const manoPayment = async () => {
    let timerInterval: any;

    setRequestMono(true);
    await investorService
      .payRecurrentPayment(transaction?.uuid, amountToBePaid, "MONO")
      .then(
        (response) => {
          setRequestMono(false);

          Swal.fire({
            title: "Attention!",
            html: "We have received your payment request. The payment authorization link would be sent to your email or via sms in <b></b> seconds.",
            timer: 50000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const timer = Swal.getPopup()?.querySelector("b");
              if (timer) {
                timerInterval = setInterval(() => {
                  timer.textContent = `${(
                    Number(Swal.getTimerLeft()) / 60000
                  ).toFixed(2)}`;
                }, 100);
              }
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result: SweetAlertResult) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.fire({
                title: "Done!",
                text: "The payment authorization link has been sent to your email or sms succesfully",
                icon: "success",
                confirmButtonText: "Ok",
              }).then((result: { isConfirmed: boolean }) => {
                if (result.isConfirmed) {
                  navigate("/estate-managers/subscriber-recurrent-payment", {
                    replace: true,
                  });
                }
              });
            }
          });
        },
        (error) => {
          setRequestMono(false);
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
          swalWithBootstrapButtons.fire({
            title: "Oops!",
            text: error.response.data.error,
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "close",
            reverseButtons: true,
          });
          console.log("project subscription Service error", error);
        }
      );
  };

  // const [requestMortgate, setRequestMortgate] = useState(false)

  // const mortgagePayment = async () =>{
  //     setRequestMortgate(true)
  //     await investorService.payRecurrentPayment(transaction?.uuid, amountToBePaid, 'MORTGAGE_CARD').then((response)=>{
  //         setRequestMortgate(false)
  //         Swal.fire({
  //             title: "Congratulations!",
  //             text: "You have successfully paid for this subscription",
  //             icon: "success",
  //             confirmButtonText: "Ok",
  //           }).then((result: { isConfirmed: boolean; }) => {
  //             if (result.isConfirmed) {
  //                 navigate('/estate-managers/subscriber-recurrent-payment', { replace: true })
  //             }
  //           });

  //         console.log("Create Subscription response", response)
  //     }, (error) =>{
  //         setRequestMortgate(false)
  //         const swalWithBootstrapButtons = Swal.mixin({
  //             customClass: {
  //               cancelButton: "btn btn-danger"
  //             },
  //             buttonsStyling: false
  //           });
  //           swalWithBootstrapButtons.fire({
  //             title: "Oops!",
  //             text: error.response.data.error,
  //             icon: "warning",
  //             showCancelButton: true,
  //             showConfirmButton: false,
  //             cancelButtonText: "close",
  //             reverseButtons: true
  //           });
  //         console.log("project subscription Service error", error)
  //     })
  // }

  return (
    <div className="EstateSubscriberRecurrentPaymentDetails">
      <div className="container">
        <div className="top-header mt-4">
          {transaction && (
            <div className="page-title">
              <h2>Payment Details</h2>
            </div>
          )}
        </div>
        {loadingRequest && <HorizontalLoader></HorizontalLoader>}
      </div>

      <div className="container mt-4">
        {transaction && (
          <div className="row">
            <div className="col-md-7">
              <div className="project-content mt-3">
                <div className="row">
                  <div className="col-4 mt-3">
                    <div className="label">Project Name:</div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="amount">{transaction.project.name}</div>
                  </div>

                  <div className="col-4 mt-3">
                    <div className="label">Project description</div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="amount">{transaction.project.details}</div>
                  </div>

                  <div className="col-4 mt-3">
                    <div className="label">Amount to be paid:</div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="amount">
                      ₦{CurrencyFormatter(transaction.amountPaidMinor)}
                    </div>
                  </div>
                  <div className="col-4 mt-3">
                    <div className="label">Amount Remaining</div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="amount">
                      ₦{CurrencyFormatter(transaction.amountRemainingMinor)}
                    </div>
                  </div>
                  <div className="col-4 mt-3">
                    <div className="label">Description:</div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="amount"> {transaction.description}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 mt-2">
              <div className="card border-light shadow-sm">
                <div className="card-body">
                  <div className="amount-label-text">Amount to be Paid</div>
                  <div className="amount-text">
                    ₦{CurrencyFormatter(amountToBePaid ?? 0)}
                  </div>

                  <label htmlFor="amountToBePayable" className="amount-label">
                    Set initial payment
                  </label>
                  <input
                    type="range"
                    step="50"
                    id="amountToBePayable"
                    className="form-range"
                    min={amountToBePayable}
                    max={remainingBalanceRaw}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                      setManualAmountToBePaidHandler(
                        Number(event.target.value)
                      );
                    }}
                  ></input>

                  {balance < transaction.amountPaidMinor && (
                    <div className="alert">
                      Your balance is low. fund wallet to continue
                    </div>
                  )}

                  <WalletInformation
                    balance={(amount) => setBalance(amount)}
                  ></WalletInformation>

                  <div className="terms mt-5 mb-3">
                    <input
                      type="checkbox"
                      onChange={(e) => setTerm(!terms)}
                    ></input>
                    I accept the{" "}
                    <Link to="/terms-and-conditions">Terms and Conditions</Link>
                  </div>

                  <div className="btn-group dropup dropup-center w-100">
                    <button
                      className="btn btn-pay-now dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="false"
                      aria-expanded="false"
                      disabled={terms || request}
                    >
                      {request && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      Pay Now
                    </button>
                    <ul className="dropdown-menu shadow">
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={createSubscription}
                        >
                          Pay with Wallet{" "}
                          {request && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={manoPayment}
                        >
                          Pay with Mono{" "}
                          {requestMono && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                        </a>
                      </li>
                      {/* <li><a className="dropdown-item" href="#" onClick={mortgagePayment}>Pay with Mortgage card {requestMortgate && <span className='spinner-border spinner-border-sm'></span>}</a></li>  */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Toaster></Toaster>
    </div>
  );
};

export default EstateSubscriberRecurrentPaymentDetails;
