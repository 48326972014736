import React, { Fragment, Key, useEffect, useState } from "react";
import "./DirectDebitApproval.scss";
import { Toaster } from "react-hot-toast";
import {
  HorizontalLoader,
  ImageSrc,
  OffcanvasDrawer,
} from "../../../../../../Components";
import { OffcanvasPlacement } from "react-bootstrap/esm/Offcanvas";

import { TimeConverter } from "../../../../../../Helpers/TimeConverter";
import { Link } from "react-router-dom";
import investorService from "../../../../../../Services/investorService";
import { mandateType } from "../../../../../../Models/mandateType";
import { CurrencyFormatter } from "../../../../../../Helpers";

const DirectDebitApproval = () => {
  const [status] = useState("ALL");
  const [mandate, setMandate] = useState<Array<mandateType>>();
  const [mandateDetails, setMandateDetails] = useState<mandateType>();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [mandateRequest, setMandateRequest] = useState(false);

  const [show, setShow] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [placement, setPlacement] = useState<OffcanvasPlacement>("end");

  const handleClose = (placement: OffcanvasPlacement | undefined) => {
    setShow(false);
  };

  const getMyMandateRequest = async () => {
    setLoadingRequest(true);
    await investorService.investorMandateRequest().then(
      (response) => {
        console.log("getMyMandateRequest 001", response.data.data);
        setLoadingRequest(false);
        setMandate(response.data.data);
      },
      (error: any) => {
        setLoadingRequest(false);
        console.log("getMyProjectPortfolio error", error);
      }
    );
  };

  useEffect(() => {
    getMyMandateRequest();
  }, [status]);

  const getMyMandateDetails = async (mandateUuid: string) => {
    setMandateRequest(true);
    await investorService.investorMandateRequestSingle(mandateUuid).then(
      (result) => {
        console.log("getMyMandateDetails", result.data.data);
        setMandateDetails(result.data.data);
        setMandateRequest(false);
      },
      (error) => {
        setMandateRequest(false);
        console.log("getMyMandateDetails error", error);
      }
    );
  };

  return (
    <div className="DirectDebitApproval">
      <div className="container">
        <div className="row mt-4">
          <div className="col-12 col-lg-10 col-xl-9 my-auto">
            <div className="page-title">Mandate</div>
            <div className="page-description">
              Here is a list of all your projects direct debit mandate
            </div>
          </div>
        </div>

        <div className="enterprise-table">
          <div className="card mt-2">
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table rounded border-0 mt-0">
                  <thead className="rounded">
                    <tr className="d-none d-md-table-row">
                      <th scope="col">S/N</th>
                      <th scope="col">Description</th>
                      <th scope="col">Date</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loadingRequest &&
                      mandate &&
                      mandate.map((res: mandateType, index: Key) => {
                        return (
                          <Fragment key={index}>
                            <tr className="d-none d-md-table-row">
                              <td> {Number(index) + 1}</td>
                              <td> {res.description}</td>
                              <td> {TimeConverter(res.createdAt)}</td>
                              <td>
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn btn-create-new border-0"
                                    onClick={() => {
                                      setShow(true);
                                      getMyMandateDetails(res.mandateUuid);
                                    }}
                                  >
                                    View
                                  </button>
                                </div>

                                {res.status === "PENDING" && (
                                  <div className="btn-group ms-2">
                                    <Link
                                      to={`/approve-mandate/${res.mandateUuid}`}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-create-new border-0"
                                      >
                                        Approve Mandate
                                      </button>
                                    </Link>
                                  </div>
                                )}
                              </td>
                            </tr>
                            <tr className="d-md-none">
                              <td>
                                <ImageSrc
                                  src={""}
                                  alt={""}
                                  title={undefined}
                                  width={"table-profile"}
                                ></ImageSrc>
                              </td>
                              <td colSpan={4}>
                                <div className="row">
                                  <div className="col-12 profile-title">
                                    {res.description}
                                  </div>
                                  <div className="col-12 profile-detail">
                                    <span className="project-details">
                                      {""}
                                    </span>
                                  </div>
                                  <div className="col-12 profile-detail">
                                    {TimeConverter(res.createdAt)}
                                  </div>
                                  <div className="col-12 profile-detail mt-2">
                                    <span className="project-status">
                                      {res.status}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="profile-btn">
                                  <button
                                    type="button"
                                    className="btn btn-sm p-1 border d-md-none"
                                    onClick={() => {
                                      setShow(true);
                                      getMyMandateDetails(res.mandateUuid);
                                    }}
                                  >
                                    View
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    {loadingRequest === false && !mandate && (
                      <tr>
                        <td colSpan={9}>
                          <div className="text-secondary text-center fw-bold">
                            No mandate found
                          </div>
                        </td>
                      </tr>
                    )}
                    {loadingRequest === true && (
                      <tr>
                        <td colSpan={9}>
                          <div className="text-secondary text-center fw-bold">
                            <HorizontalLoader></HorizontalLoader>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OffcanvasDrawer
        show={show}
        placement={placement}
        handleClose={handleClose}
        title={"Mandate Details"}
        description=""
      >
        <React.Fragment>
          {mandateRequest && <HorizontalLoader></HorizontalLoader>}
          <div className="mandateDetails">
            {!mandateRequest && (
              <ul className="timeline">
                <li>
                  <b>Project Name:</b>{" "}
                  {mandateDetails?.projectSubscriptions.project.name}
                </li>
                <li>
                  <b>Project Details:</b>{" "}
                  {mandateDetails?.projectSubscriptions.project.details}
                </li>
                <li>
                  <b>Description:</b> {mandateDetails?.description}
                </li>
                <li>
                  <b>Project Payment Duration Plan:</b>{" "}
                  {mandateDetails?.projectSubscriptions.durationPerPaymentPlan}
                </li>
                <li>
                  <b>Subscription Status:</b>{" "}
                  {mandateDetails?.projectSubscriptions.status}
                </li>
                <li>
                  <b>Number of slot:</b>{" "}
                  {mandateDetails?.projectSubscriptions.numberOfSlots}
                </li>
                <li>
                  <b>Total Amount:</b>{" "}
                  {CurrencyFormatter(
                    mandateDetails?.projectSubscriptions.amountDueMinor ?? 0
                  )}
                </li>
                <li>
                  <b>Duration Remaining:</b>{" "}
                  {mandateDetails?.projectSubscriptions.durationLeft}
                </li>

                <li>
                  <b>Direct debit start date:</b>{" "}
                  {TimeConverter(mandateDetails?.startDate)}
                </li>
                <li>
                  <b>Direct debit end date:</b>{" "}
                  {TimeConverter(mandateDetails?.endDate)}
                </li>

                <li>
                  <b>Date:</b> {TimeConverter(mandateDetails?.createdAt)}
                </li>
              </ul>
            )}

            {!mandateRequest && mandateDetails?.status === "PENDING" && (
              <div className="btn-group ms-2">
                <Link to={`/approve-mandate/${mandateDetails?.mandateUuid}`}>
                  <button type="button" className="btn btn-create-new border-0">
                    Approve Mandate
                  </button>
                </Link>
              </div>
            )}
          </div>
        </React.Fragment>
      </OffcanvasDrawer>
      <Toaster></Toaster>
    </div>
  );
};

export default DirectDebitApproval;
