import axios from "axios";
import { AxiosInterceptor } from "../Config/AxiosInterceptor";

AxiosInterceptor();

const quoteRequestService = {
  async getAllMyRequest(pageNumber, sortOrder, persona, pendingResponse) {
    return await axios.get(
      `/quoterequests?pageNumber=${pageNumber}&sortOrder=${sortOrder}&persona=${persona}&pendingResponse=${pendingResponse}`
    );
  },

  async getAllMyRequestPending(
    pageNumber,
    sortOrder,
    persona,
    pendingResponse
  ) {
    return await axios.get(
      `/quoterequests?pageNumber=${pageNumber}&sortOrder=${sortOrder}&persona=${persona}&pendingResponse=${pendingResponse}`
    );
  },

  async sendQuoteRequest(data) {
    return await axios.post(`/quoterequests`, data);
  },
  async saveCost(quoteUuid, data) {
    return await axios.post(
      `/quoterequests/${quoteUuid}/seller_response`,
      data
    );
  },
  async getSingleRequest(orderId) {
    return await axios.get(`/quoterequests/${orderId}`);
  },
  async declineRequest(orderId) {
    return await axios.put(`/quoterequests/${orderId}/seller_response/decline`);
  },
};
export default quoteRequestService;
