import React, { PropsWithChildren, useEffect, useState } from "react";
import "./TopLocation.scss";
import { ImageSrc } from "../../../../../../../Components";
// import estatelocation from "../../../../../../../Assets/Images/estate/estate-location-mg.png"
import projectService from "../../../../../../../Services/projectService";
import { projectLocationType } from "../../../../../../../Models/projectType";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

const TopLocation = () => {
  const [projectLocations, setProjectLocations] =
    useState<Array<projectLocationType>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getTopLocation = async () => {
    setIsLoading(true);
    await projectService.getTopLocations().then(
      (response) => {
        setProjectLocations(response.data.data);
        setIsLoading(false);
        console.log("getTopLocations", response);
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    getTopLocation();
  }, []);

  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);

  function Box({ children }: PropsWithChildren<unknown>) {
    return (
      <div
        style={{
          border: "1px solid #fff",
          display: "block",
          lineHeight: 2,
          padding: "0rem",
          marginBottom: "0.1rem",
          width: "100%",
          //   height: 137,
        }}
      >
        {children}
      </div>
    );
  }

  const swiperOptions = {
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 270,
      },
      674: {
        slidesPerView: 2,
        spaceBetween: 0,
      },
      1104: {
        slidesPerView: 3,
        spaceBetween: 50,
      },

      1402: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  };

  const navigate = useNavigate();

  return (
    <div className="TopLocation">
      <div className="container">
        <div className="row">
          <div className="col-8">
            <div className="toplocation">
              <div className="title">Top Locations</div>
              <div className="description">
                Checkout projects in our top location
              </div>
            </div>
          </div>
          <div className="col-4 my-auto">
            <div className="text-center">
              <button ref={(node) => setPrevEl(node)} className="btn border-0">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.6673 14.6673H10.4406L17.894 7.21398L16.0006 5.33398L5.33398 16.0006L16.0006 26.6673L17.8806 24.7873L10.4406 17.334H26.6673V14.6673Z"
                    fill="#AFD4E2"
                  />
                </svg>
              </button>
              <button
                ref={(node) => setNextEl(node)}
                className="btn border-0"
                type="button"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.0007 5.33398L14.1207 7.21398L21.5606 14.6673H5.33398V17.334H21.5606L14.1207 24.7873L16.0007 26.6673L26.6673 16.0007L16.0007 5.33398Z"
                    fill="#AFD4E2"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5 mb-3">
        {!isLoading && projectLocations && projectLocations.length > 0 && (
          <Swiper
            //   slidesPerView={4}
            spaceBetween={30}
            className="mySwiper"
            modules={[Pagination, Navigation]}
            slidesPerGroup={2}
            loop={true}
            loopFillGroupWithBlank={true}
            navigation={{ prevEl, nextEl }}
            breakpoints={swiperOptions.breakpoints}
          >
            {!isLoading &&
              projectLocations?.map(
                (location: projectLocationType, index: React.Key) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="card border-0">
                        <div
                          className="card-image"
                          onClick={() => {
                            navigate(
                              `/estate-managers/subscriber-projects/${location.projectUuid}`,
                              { replace: true }
                            );
                          }}
                        >
                          <ImageSrc
                            src={location.images[0]?.url ?? ""}
                            alt={""}
                            title={undefined}
                            width={"w-100"}
                          ></ImageSrc>
                        </div>
                        <div className="card-body">
                          <div className="details">
                            <div className="details-inner shadow-sm">
                              <div>
                                <div className="title">{location.state}</div>
                                <div className="desc">
                                  <svg
                                    className=""
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6.5 11H2.5C1.5 11 1 10.5 1 9.5V5.5C1 4.5 1.5 4 2.5 4H5V9.5C5 10.5 5.5 11 6.5 11Z"
                                      stroke="#303132"
                                      strokeWidth="0.5"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M5.05499 2C5.01499 2.15 5 2.315 5 2.5V4H2.5V3C2.5 2.45 2.95 2 3.5 2H5.05499Z"
                                      stroke="#303132"
                                      strokeWidth="0.5"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M7 4V6.5"
                                      stroke="#303132"
                                      strokeWidth="0.5"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M9 4V6.5"
                                      stroke="#303132"
                                      strokeWidth="0.5"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M8.5 8.5H7.5C7.225 8.5 7 8.725 7 9V11H9V9C9 8.725 8.775 8.5 8.5 8.5Z"
                                      stroke="#303132"
                                      strokeWidth="0.5"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M3 6.5V8.5"
                                      stroke="#303132"
                                      strokeWidth="0.5"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M5 9.5V2.5C5 1.5 5.5 1 6.5 1H9.5C10.5 1 11 1.5 11 2.5V9.5C11 10.5 10.5 11 9.5 11H6.5C5.5 11 5 10.5 5 9.5Z"
                                      stroke="#303132"
                                      strokeWidth="0.5"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>{" "}
                                  {location.numberOfSlots} unit
                                  {location.numberOfSlots > 1 && "s"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                }
              )}
          </Swiper>
        )}

        {isLoading === true && (
          <Swiper
            slidesPerView={4}
            spaceBetween={30}
            className="mySwiper"
            modules={[Navigation]}
            slidesPerGroup={2}
            loop={true}
            loopFillGroupWithBlank={true}
            breakpoints={swiperOptions.breakpoints}
            navigation={{ prevEl, nextEl }}
          >
            {[...Array(5)].map((res: any, index: React.Key) => {
              return (
                <SwiperSlide key={index}>
                  <div className="card border-0">
                    <div className="card-image-skeleton">
                      <Skeleton
                        wrapper={Box}
                        count={1}
                        height={300}
                        width={"100%"}
                        className="product-image w-100"
                      />
                    </div>
                    <div className="card-body">
                      <div className="details">
                        <div className="details-inner shadow-sm">
                          <div>
                            <Skeleton
                              wrapper={Box}
                              count={1}
                              height={100}
                              width={"100%"}
                              className="product-image w-100"
                            />

                            <Skeleton
                              wrapper={Box}
                              count={1}
                              height={100}
                              width={"100%"}
                              className="product-image w-100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default TopLocation;
