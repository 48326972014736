
import React, { useState, FC } from 'react'
import CloseIcon from "../../../../../../Assets/Images/icon/close_icon.svg";
import Modal from 'react-bootstrap/Modal';
import "./EstateAccountNameChangeRequest.scss"
import { useForm } from 'react-hook-form';  
// eslint-disable-next-line no-unused-vars
import toast, { Toaster } from 'react-hot-toast';  
import userService from '../../../../../../Services/userService';
 

 
interface propTypes  {
    showModal:boolean;
    handleClose:any;
} 

interface formProps {
    firstName:string;
    lastName:string;
    middleName:string;
  }
  
const EstateAccountNameChangeRequest:FC<propTypes> = ({showModal, handleClose}) => {
    
 
    const [submitRequest, setSubmitRequest] = useState(false);
  
   
    const { handleSubmit, register, formState: { errors } } = useForm<formProps>()
  
    const onsubmit = handleSubmit((data:any) => processForm(data))
  
    const processForm = async (data: any) => {
      setSubmitRequest(true)

      await userService.changeName(data).then((res)=>{ 
        setSubmitRequest(false)
        handleClose()
        toast.success("Name change request sent successful", { duration: 20000, className: 'bg-white text-dark' }); 
      }, error =>{  
         setSubmitRequest(false)
        toast.error(error.response.data.error, { duration: 20000, className: 'bg-white text-dark' }); 
      })
    }
   
    return (
        <Modal show={showModal} onHide={handleClose} className="estate-custom-modal">
            
            <Modal.Header className="custom-modal-header">
            
                <div className="d-flex flex-row justify-content-between w-100">
                    <div className="custom-modal-close-btn order-2"
                        onClick={handleClose}
                    >
                        <img src={CloseIcon} alt=''/>
                    </div>
                    <p className="custom-modal-title order-1">Request Account Name Change</p>
                </div>
              
            </Modal.Header> 
            <Modal.Body className="custom-modal-body"> 
                <form onSubmit={onsubmit}> 
                      <div className='form-input'> 
                          <div className='form-input-wrap mt-3'>
                            <label htmlFor="firstname">First Name </label>
                              <input id="firstname" type="text" className='form-control ps-2 mt-2' {...register("firstName", { required: "This field is required"})} placeholder="John"/>
                               {errors.firstName && (<div className="text-danger">{errors.firstName.message}</div>)}
                          </div> 
                          <div className='form-input-wrap mt-3'>
                            <label htmlFor="lastName">Last Name </label>
                              <input id="lastName" type="text" className='form-control ps-2 mt-2' {...register("lastName", { required: "This field is required" })} placeholder="Doe"/>
                               {errors.lastName && (<div className="text-danger">{errors.lastName.message}</div>)}
                          </div> 
                          <div className='form-input-wrap mt-3'>
                            <label htmlFor="middleName">Middle Name </label>
                              <input id="middleName" type="text" className='form-control ps-2 mt-2' {...register("middleName", { required: "This field is required"})} placeholder="Optional"/>
                               {errors.middleName && (<div className="text-danger">{errors.middleName.message}</div>)}
                          </div> 

                          {!submitRequest && <button className='btn w-100 add-btn mt-4 text-light'> Send Request </button>}
                          {submitRequest && <button className="btn w-100 add-btn mt-4 text-light" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...
                          </button> }
                      </div>
                  </form>  
            </Modal.Body> 
            <Toaster></Toaster>
        </Modal>
    )
}

export default EstateAccountNameChangeRequest;