import React, { FC } from "react";
import "./ProjectItem.scss";
import { Link, useNavigate } from "react-router-dom";
import { projectDetailsType } from "../../../../../../../Models/projectType";
import { CurrencyFormatter } from "../../../../../../../Helpers";
import { ImageSrc } from "../../../../../../../Components";

interface propsType {
  project: projectDetailsType;
}

const ProjectItem: FC<propsType> = ({ project }) => {
  const navigate = useNavigate();
  return (
    <div className="ProjectItem mb-4">
      <div className="card border-0">
        <div
          className="card-image"
          onClick={() =>
            navigate(
              `/estate-managers/subscriber-projects/${project.projectUuid}`,
              { replace: true }
            )
          }
        >
          <ImageSrc
            src={project.images[0]?.url}
            width="w-100"
            alt={project.name}
            title={project.name}
          ></ImageSrc>
          <div className="featured-text">{project.type}</div>

          {project.isMortgageAvailable === true && (
            <div className="featured-text-2">Mortgage available</div>
          )}
        </div>
        <div className="card-body p-1">
          <div className="row p-0 mt-3">
            <div className="col-9">
              <div className="price">
                ₦{CurrencyFormatter(project.costPerSlot)}{" "}
                <small>per unit</small>
              </div>
            </div>
            <div className="col-3 d-flex justify-content-end">
              <span className="units">{project.numberOfSlots} units</span>
            </div>
          </div>

          <div className="project-title mt-1">
            <Link
              to={`/estate-managers/subscriber-projects/${project.projectUuid}`}
            >
              {project.name}
            </Link>
          </div>
          <div className="project-description mt-1">{project.details}</div>

          <div className="project-location mt-2">
            <svg
              width="11"
              height="11"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.99992 8.95297C9.14867 8.95297 10.0799 8.02172 10.0799 6.87297C10.0799 5.72422 9.14867 4.79297 7.99992 4.79297C6.85117 4.79297 5.91992 5.72422 5.91992 6.87297C5.91992 8.02172 6.85117 8.95297 7.99992 8.95297Z"
                stroke="#797A7C"
                strokeWidth="1.5"
              />
              <path
                d="M2.41379 5.66065C3.72712 -0.112681 12.2805 -0.106014 13.5871 5.66732C14.3538 9.05399 12.2471 11.9207 10.4005 13.694C9.06046 14.9873 6.94046 14.9873 5.59379 13.694C3.75379 11.9207 1.64712 9.04732 2.41379 5.66065Z"
                stroke="#797A7C"
                strokeWidth="1.5"
              />
            </svg>
            {project.state}
          </div>

          <Link
            to={`/estate-managers/subscriber-projects/${project.projectUuid}`}
            className="mt-3"
          >
            <button className="btn w-100 mt-4">
              <svg
                width="20"
                height="20"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0819 11.9999C16.0819 13.9799 14.4819 15.5799 12.5019 15.5799C10.5219 15.5799 8.92188 13.9799 8.92188 11.9999C8.92188 10.0199 10.5219 8.41992 12.5019 8.41992C14.4819 8.41992 16.0819 10.0199 16.0819 11.9999Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.4998 20.2707C16.0298 20.2707 19.3198 18.1907 21.6098 14.5907C22.5098 13.1807 22.5098 10.8107 21.6098 9.4007C19.3198 5.8007 16.0298 3.7207 12.4998 3.7207C8.96984 3.7207 5.67984 5.8007 3.38984 9.4007C2.48984 10.8107 2.48984 13.1807 3.38984 14.5907C5.67984 18.1907 8.96984 20.2707 12.4998 20.2707Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              View Details
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectItem;
